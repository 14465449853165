/*
  --------------------------------------------------------------WELCOME PAGE
  */
.welcome-title {
  background-image: url("https://i.imgur.com/MU0NA3k.png");
  margin-top: 0;
}

@media (min-width: 1024px) {
  .welcome-title {
    height: 800px;
  }
}

.outerbrain-logo {
  margin-top: 0;
  width: 320px;
  max-width: 100%;
  animation: pulse 1.5s infinite;
  animation-timing-function: linear;
}

@media (min-width: 1024px) {
  .outerbrain-logo {
    width: 500px;
    margin-top: -100px;
    
  }
}

.fade-in-element {
  animation: fade-in 1.4s;
}
